import {
  Box,
  Card,
  Typography,
  Divider,
  MenuList,
  MenuItem,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CircleIcon from "@mui/icons-material/Circle";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/store/configureStore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import agent from "../../../app/api/agent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Props {
  contractProgress: any;
  progressTracker: any;
  onHandleLvL2ClickItemClick: (id: any) => void;
  dateTimeTracking: any;
}

function DealProgressDrawer({
  contractProgress,
  progressTracker,
  onHandleLvL2ClickItemClick,
  dateTimeTracking,
}: Props) {
  const {user} = useAppSelector((state) => state.account);
  const [menu1Open, setMenu1Open] = useState(false);
  const [menu1IdOpen, setMenu1IdOpen] = useState<number>();
  const [daysinDeal, setDaysInDeal] = useState();

  function handleClick(menuId: number) {
    if (menuId) {
      if (menuId === menu1IdOpen) {
        setMenu1Open((prevState) => !prevState);

        if (menu1Open) {
          setMenu1IdOpen(undefined);
        }
      } else {
        setMenu1IdOpen(menuId);
        setMenu1Open(true);
        onHandleLvL2ClickItemClick(menuId);
      }
    }
  }
  useEffect(() => {
    if (dateTimeTracking) {
      agent.ContractProgressDeal.getDealDuration(dateTimeTracking).then(
        (response) => {
          setDaysInDeal(response.days);
        }
      );
    }
  }, [dateTimeTracking]);

  return (
    <>
      <Card
        sx={{
          backgroundColor: "#F2F6FA",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "300px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingY={0.5}
        >
          <DateRangeIcon sx={{color: "#0D2561"}} />
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
              color: "#616161",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "36px",
              paddingLeft: "20px",
            }}
          >
            Duration: &nbsp;{" "}
            <b>{dateTimeTracking === undefined ? "N/A" : daysinDeal} Days</b>
          </Typography>
        </Box>
        <Divider
          sx={{
            marginLeft: "15px",
            marginRight: "15px",
            border: "1px solid #0D2561",
          }}
        />
        <MenuList sx={{overflowY: "auto"}}>
          {contractProgress &&
            contractProgress.mvpPhases.$values.map(
              (item: any, index: any): any => (
                <div key={index} style={{paddingTop: "10px"}}>
                  <MenuItem key={item.$id + index}>
                    {item.isCompleted === true ? (
                      <CheckCircleIcon
                        fontSize="medium"
                        sx={{color: "#0D2561"}}
                      />
                    ) : (
                      <CircleIcon fontSize="medium" sx={{color: "#0D2561"}} />
                    )}
                    <Typography
                      sx={{
                        paddingLeft: "10px",
                        color: "#000000DE",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                    >
                      {item.phaseName}
                    </Typography>
                  </MenuItem>

                  {item.taskMessages.$values.map(
                    (element: any, index: any): any => (
                      <>
                        <MenuItem
                          key={element.$id + element.index}
                          sx={{
                            display: "flex",
                            alignItems: "center", // Align items vertically
                            justifyContent: "space-between", // Ensures space between elements
                          }}
                          onClick={() => handleClick(element.id)}
                        >
                          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                            {progressTracker.includes(element.id) ? (
                              <CheckCircleIcon fontSize="small" sx={{ color: "rgba(13, 37, 97, 1)" }} />
                            ) : (
                              <CircleIcon fontSize="small" sx={{ color: "#9E9E9E" }} />
                            )}
                            <Typography
                              sx={{
                                paddingLeft: "10px",
                                color: "#0D2561",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "20px",
                                textAlign: "left",
                                textWrap: "wrap",
                              }}
                            >
                              {element.taskMessageName}
                            </Typography>
                          </Box>
                          {menu1IdOpen === element.id ? (
                            <KeyboardArrowUpIcon sx={{ color: "#0D2561" }} />
                          ) : (
                            <KeyboardArrowDownIcon sx={{ color: "#0D2561" }} />
                          )}
                        </MenuItem>
                        {menu1Open && menu1IdOpen === element.id && (
                          <MenuList>
                            {user?.profession === "Buyer" &&
                            menu1IdOpen === 1 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review property documents</li>
                                  <li>
                                    If required: Deposit 1st Escrow Payment [External]{" "}
                                  </li>
                                  <li>
                                    Upload Escrow Receipt to Receipts for
                                    Escrow/Principal Payments
                                  </li>
                                  <li>
                                    Upload Mortgage Pre-Approval to Contract –
                                    Mortgage Pre-Approval{" "}
                                  </li>
                                  <li>Or: Add your Lender</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 1 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Add your Buyer</li>
                                  <li>Review property documents</li>
                                  <li>
                                    Upload P&S Agreement to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 2 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Request documents from Buyer</li>
                                  <li>Upload Mortgage Pre-Approval</li>
                                  <li>OR: Upload documents for signature</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 2 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload your documents to Mortgage - Buyer
                                    Documents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              menu1IdOpen === 3 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign documents provided by Lender</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 4 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Upload Mortgage Pre-Approval</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 5 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve Mortgage Pre-Approval</li>
                                  <li>Approve Escrow Payment Receipt</li>
                                  <li>Sign P&S Agreement</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 5 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Review P&S Documents
                                  </li>
                                  <li>Add your Solicitor, if not done yet</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 5 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                  <li>
                                    Approve all Contract Documents (P&S, Escrow,
                                    Mortgage)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 6 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Agreement</li>
                                  <li>Sign addtl. documents as needed</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 7 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Fill out Offer Form and submit</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 9 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Add the Seller to the workflow (add partner)
                                  </li>
                                  <li>
                                    Approve P&S Document
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 9 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all P&S Agreement Documents</li>
                                  <li>Add your solicitor, if not done yet</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 9 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve all P&S Agreement Documents
                                  </li>
                                  <li>
                                    OR: Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 10 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 10 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 8 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Accept selected Offer (notifies the Buyer
                                    Side)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all agreement documents</li>
                                  <li>
                                    OR: Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                  <li>Sign amendment documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 11 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all agreement documents</li>
                                  <li>
                                    OR: Upload Amendments to Contract - P&S
                                    Agreement and Contract Amendment(s){" "}
                                  </li>
                                  <li>Sign amendment documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 12 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 12 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Sign P&S Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 13 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review property documents</li>
                                  <li>Request additional documents, if needed, from Seller</li>
                                  <li>
                                    Upload Surveyor Report - Report
                                    Documents
                                  </li>
                                  <li>OR: Add your Property Surveyor</li>
                                </ul>
                              </MenuItem>
                          ) : user?.profession === "Solicitor" &&
                            element.id === 13 ? (
                            <MenuItem
                              sx={{
                                color: "#0D2561",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: "0.4px",
                              }}
                            >
                              <ul
                                style={{
                                  padding: 0,
                                  paddingLeft: "20px",
                                  paddingRight: "10px",
                                }}
                              >
                                <li>Review property documents</li>
                                <li>Request additional documents, if needed, from Seller</li>
                                <li>
                                  Upload Due Dilligence - Report
                                  Documents
                                </li>
                                <li>Upload AML Report to Due Diligence - Exchange Document(s) between Buyer Side & Seller Side</li>
                              </ul>
                            </MenuItem>
                          ) : user?.profession === "Representative" &&
                              element.id === 13 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review property documents</li>
                                  <li>
                                    Upload reports to Due Dilligence - Report
                                    Documents
                                  </li>
                                  <li>Upload AML Report to Diligence - Exchange Document(s) between Buyer Side & Seller Side</li>
                                  <li>OR: Add other Service Provider(s) if needed </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "PropertySurveyor" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Select the best time from availabilities
                                  </li>
                                  <li>
                                    Upload invoice to Due Diligence -
                                    Invoices/Receipts
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "PropertyValuator" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Select the best time from availabilities
                                  </li>
                                  <li>
                                    Upload invoice to Due Diligence -
                                    Invoices/Receipts
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 14 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select Your Availability</li>
                                  <li>OR: Not Attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "PropertySurveyor" &&
                              element.id === 15 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Surveyor Report to Due Diligence -
                                    Report Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "PropertyValuator" &&
                              element.id === 15 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Surveyor Report to Due Diligence -
                                    Report Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 15 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Pay service provider fee [External]
                                  </li>
                                  <li>
                                    Upload payment receipt to Due Diligence –
                                    Invoices/Receipts
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 16 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review all Due Diligence documents</li>
                                  <li>
                                    OR: Upload any Work Request to Due Diligence - Work Request
                                  </li>
                                  <li>Add other Service Provider(s) if needed</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 16 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve all Due Diligence documents</li>
                                  <li>
                                    OR: Upload any Work Request to Due Diligence - Work Request
                                  </li>
                                  <li>Add other Service Provider(s) if needed</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                            element.id === 16 ? (
                            <MenuItem
                              sx={{
                                color: "#0D2561",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: "0.4px",
                              }}
                            >
                              <ul
                                style={{
                                  padding: 0,
                                  paddingLeft: "20px",
                                  paddingRight: "10px",
                                }}
                              >
                                <li>Approve all Due Diligence documents</li>
                                <li>
                                  OR: Upload any Work Request to Due Diligence - Work Request
                                </li>
                              </ul>
                            </MenuItem>
                          ): user?.profession === "Solicitor" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Due Diligence – Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Due Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                  Approve and Sign Due Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                  Approve and Sign Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve and Sign Due Diligence - Work Request and Agreement
                                  </li>
                                  <li>
                                    Approve and Sign Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                  <li>
                                    Upload proof of completed work to Due Diligence
                                    – Exchange Document(s) btw Buyer Side &
                                    Seller Side{" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                   Approve and Sign Due Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                  Approve and Sign Contract - P&S Agreement and
                                    Contract Amendment(s)
                                  </li>
                                  <li>
                                    Upload proof of completed work to Due Diligence
                                    – Exchange Document(s) btw Buyer Side &
                                    Seller Side
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 17 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload/approve Due Diligence - Work Request and
                                    Agreement
                                  </li>
                                  <li>
                                    Upload/approve Contract - P&S Agreement and
                                    Contract Amendment(s){" "}
                                  </li>
                                  <li>
                                    Upload proof of completed work to Due Diligence
                                    – Exchange Document(s) btw Buyer Side &
                                    Seller Side
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 18 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Sign Due Diligence - Work Request Agreement
                                  </li>
                                  <li>
                                    Sign Contract - Amendment(s), if required
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 18 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Sign Due Diligence - Work Request Agreement
                                  </li>
                                  <li>
                                    Sign Contract - Amendment(s), if required
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 19 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload Approval to Mortgage - Final Completion
                                    Documents
                                  </li>
                                  <li>OR: Add your Lender, if not done yet</li>
                                  <li>
                                    Upload documents to Mortgage - Buyer
                                    Documents(s) as needed
                                  </li>
                                  <li>Deposit 2nd Escrow Payment [External]</li>
                                  <li>
                                    Upload Escrow receipt to Contract -
                                    Escrow/Princial Payments
                                  </li>
                                  <li>
                                    Upload Insurance Policies to Insurance –
                                    Insurance Policies{" "}
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 19 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Upload Appraisal Report</li>
                                  <li>
                                    Upload documents to Lender Document(s) for
                                    Buyer Signature
                                  </li>
                                  <li>
                                    Upload Approval to Mortgage - Completion
                                    Documents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 20 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Sign documents in Lender Document(s) for
                                    Buyer Signature
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 21 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve Mortgage - Completion Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 21 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve Mortgage - Completion Documents</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    If requested, Upload documents to Ownership - Title
                                    Report(s) and Exchange Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                <li>
                                  Upload Title Deed Document(s) to Ownership - Title Deed Document(s)
                                </li>
                                <li>
                                  Upload title issue reports to Ownership - Title Report(s) and Exchange Document(s)
                                </li>
                                <li>
                                  Upload documents to Title Deed. Document(s) for Seller Signature, if required
                                </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 22 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                  If not done by your Solicitor, then upload Final Title Deed Document to Ownership - Final Title and Insurance Document(s)
                                  </li>
                                  <li>
                                    Upload Insurance Policy to Ownership - Final Title and Insurance Document(s)
                                  </li>
                                  <li>OR: Add your Insurer</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 23 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve and sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 23 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve and sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review Deed and Insurance Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Review Deed and Insurance Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve Deed and Insurance Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 24 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve Deed and Insurance Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Deposit Final Escrow Payment [External]
                                  </li>
                                  <li>
                                    Upload Escrow receipt to Completion - Receipts
                                    for Final Payments
                                  </li>
                                  <li>Prepare for Identity Verification</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Prorate and adjust documents for Closing
                                  </li>
                                  <li>
                                    Request additional documents, if needed
                                  </li>
                                  <li>
                                    If needed before closing, upload documents to Closing - Documents for Buyer/Seller Signature
                                  </li>
                                  <li>
                                    Upload documents as requested to Closing - Final Closing Statement Documents
                                  </li>
                                  <li>Prepare for Identity Verification</li>
                                  <li>
                                    Upload Final Completion Statement Document(s)
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 25 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Upload utility readings to "Completion - Seller
                                    Documents"
                                  </li>
                                  <li>
                                    Upload documents as requested to Closing - Final Closing Statement Documents
                                  </li>
                                  <li>Prepare for Identity Verification</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 26 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve and sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 26 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Approve and sign documents as required</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Completion - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Completion Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Completion - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Completion Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Completion - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Completion Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Approve payments in Completion - Receipts for
                                    Final (Escrow/Principal) Payments
                                  </li>
                                  <li>Approve Completion Statement Document(s)</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Lender" &&
                              element.id === 27 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Deposit Principal Amount [External]</li>
                                  <li>Upload Payment Receipt to Completion - Payments Receipts</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Review availabilities and select the best
                                    time slot
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Representative" &&
                              element.id === 28 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Select 3 availability options</li>
                                  <li>OR: Not attending</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Solicitor" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Conduct Closing Procedures
                                  </li>
                                  <li>
                                  Release all Fee and Escrow Payments and upload Payment Receipts
                                  </li>
                                  <li>
                                  Upload final closing statement to Closing - Executed Transaction Documents
                                  </li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Buyer" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                    <li>
                                      Follow instructions given by Attorneys
                                    </li>
                                    <li>
                                      Approve and sign documents as requested
                                    </li>
                                  </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 29 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>
                                    Follow instructions given by Attorneys
                                  </li>
                                  <li>
                                    Approve and sign documents as requested
                                  </li>
                                </ul>
                              </MenuItem>
                            ) :  user?.profession === "Buyer" &&
                              element.id === 31 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Confirm receipt of Property Access</li>
                                </ul>
                              </MenuItem>
                            ) : user?.profession === "Seller" &&
                              element.id === 31 ? (
                              <MenuItem
                                sx={{
                                  color: "#0D2561",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <li>Confirm receipt of principal payment</li>
                                </ul>
                              </MenuItem>
                            ) : null}
                          </MenuList>
                        )}
                      </>
                    )
                  )}
                </div>
              )
            )}
        </MenuList>
      </Card>
    </>
  );
}

export default DealProgressDrawer;
