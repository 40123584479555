import {
  Box,
  Card,
  CardActions,
  CardContent,
  Fade,
  Typography,
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import DealActionDrawer from "../dealAction/DealActionDrawer";
import DealInterestDrawer from "../dealInterest/DealInterestDrawer";
import VerticalDivider from "../../../app/components/VerticalDividerCard";
import CardActionsOverview from "./CardActionsOverview";
import {scrollCardOutOfView} from "../../../app/util/util";
import {useAppSelector} from "../../../app/store/configureStore";
import agent from "../../../app/api/agent";
import FlagIcon from "@mui/icons-material/Flag";

interface Props {
  property: any;
}

const DealCard = ({property}: Props) => {
  const {openCardDrawerWithId} = useAppSelector((state) => state.messageCenter);
  const boxRef = useRef<Element>();
  const {user} = useAppSelector((state) => state.account);
  const {openDealCardActionDrawerId} = useAppSelector((state) => state.ui);
  const prevScrollableAreaPosition = useRef<number>();
  const [openDrawer, setOpenDrawer] = useState({
    actionDrawer: false,
    interestDrawer: false,
  });
  const [contractProgress, setContractProgress] = useState<any>();
  const [progressTracker, setProgressTracker] = useState([]);
  const [openProgressDrawer, setOpenProgressDrawer] = useState({
    openProgressDrawer: false,
  });
  const [clickedItemId, setClickedItemId] = useState(null);
  const navigate = useNavigate();
  const [propertyStatus, setPropertyStatus] = useState(property.status);

  const handleLvl2ItemClick = (id: any) => {
    setClickedItemId(id);
  };

  useEffect(() => {
    if (openDealCardActionDrawerId !== property.contractProgressId) return;

    agent.ContractProgressDeal.contractProgressDeal(
      property.contractProgressId
    ).then((response) => {
      setContractProgress(response);
    });

    setOpenDrawer({
      actionDrawer: true,
      interestDrawer: false,
    });
    setOpenProgressDrawer({openProgressDrawer: true});
  }, [
    openDealCardActionDrawerId,
    user?.profession,
    property.contractProgressId,
  ]);

  useEffect(() => {
    if (openCardDrawerWithId === property.contractProgressId)
      setOpenDrawer({
        actionDrawer: true,
        interestDrawer: false,
      });
    setOpenProgressDrawer({openProgressDrawer: true});
    agent.ContractProgressDeal.contractProgressDeal(
      property.contractProgressId
    ).then((response) => setContractProgress(response));
  }, [openCardDrawerWithId, property.contractProgressId]);

  useEffect(() => {
    if (property.contractProgressId)
      agent.ContractProgressDeal.getmvptaskmessagechecker(
        property.contractProgressId
      ).then((response) => {
        const newArr = response.$values.map(
          (item: any) => item.mvpTaskMessageId
        );
        setProgressTracker(newArr);
      });
  }, [property.contractProgressId]);

  function getContractProgress() {
    if (property.contractProgressId) {
      agent.ContractProgressDeal.contractProgressDeal(
        property.contractProgressId
      )
        .then((response) => {
          setContractProgress(response);
        })
        .catch((error) => console.log(error.response));
    }
  }
  const scrollableArea = document.querySelector(
    ".cards-scrollable-area"
  ) as HTMLElement;

  const handlePropertyOverview = (propertyId: number, contractId: number) => {
    document.body.style.overflowY = "auto";
    scrollableArea.style.overflowY = "auto";

    navigate(`/deals/overview/${propertyId}/${contractId}`);
  };

  const fullAddress = `${property.property.addressLine} ${property.property.city}, ${property.property.state} ${property.property.postalCode} ${property.property.country}`;

  if (openDrawer.actionDrawer || openDrawer.interestDrawer) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
  }

  return (
    <Box ref={boxRef}>
      <Card
        key={property.$id}
        sx={{
          border: "1px solid #AACCDD",
          boxShadow: "none",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0px",
          width: "100%",
          height: "154px",
          marginBottom: "15px",
          color: "#424242",
        }}
      >
        <CardContent
          sx={{
            minWidth: "245px",
            maxWidth: "245px",
            padding: "0px",
            marginRight: "10px",
            height: "154px",
          }}
        >
          <Box
            onClick={() =>
              handlePropertyOverview(
                property.propertyId,
                property.contractProgressId
              )
            }
            style={{textDecoration: "none", color: "#757575"}}
          >
            <img
              src={property.property.mainImageUrl}
              alt=""
              style={{
                height: "154px",
                width: "100%",
                padding: "0px",
                margin: "0px",
                objectFit: "cover",
              }}
            />
          </Box>
        </CardContent>
        <CardActions sx={{width: "15%"}}>
          <Box
            onClick={() =>
              handlePropertyOverview(
                property.propertyId,
                property.contractProgressId
              )
            }
            style={{textDecoration: "none", color: "#757575"}}
          >
            <Typography sx={{color: "#424242"}} variant="h5">
              £{property.property.listPrice?.toLocaleString()}
            </Typography>
            <Typography sx={{lineHeight: "1.2", fontSize: "0.75rem"}}>
              {fullAddress}
            </Typography>
            <Typography variant="subtitle1">#{property.propertyId}</Typography>
            <Typography variant="subtitle1">
              Deal#{property.contractProgressId}
            </Typography>
          </Box>
        </CardActions>
        <VerticalDivider />

        <Box
          sx={{
            width: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
          }}
        >
          {(user?.profession === "Buyer" && property.property.hasLenderOffer) ||
          (user?.profession === "Seller" && property.property.hasLenderOffer) ||
          (user?.profession === "Buyer" && property.property.hasServiceOffer) ||
          (user?.profession === "Seller" && property.property.hasServiceOffer) ? (
            <Link
              to={`/deals/lenderoffers/${property.propertyId}/${property.contractProgressId}`}
              style={{
                textDecoration: "none",
                color: "#757575",
                width: "10%",
                height: "100%",
                display: "flex",
                padding: "15px",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                onClick={() =>
                  setOpenDrawer((prevState) => {
                    return {
                      actionDrawer: false,
                      interestDrawer: !prevState.interestDrawer,
                    };
                  })
                }
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FlagIcon
                  sx={{
                    color: "rgba(38, 153, 251, 1)",
                    fontSize: "40px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "rgba(97, 97, 97, 1)",
                    font: "Roboto",
                    fontWeight: "400",
                    lineHeight: "20px",
                    fontSize: "14px",
                  }}
                >
                  Offers
                </Typography>
              </Box>
            </Link>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textDecoration: "none",
                color: "#757575",
                width: "10%",
                height: "100%",
                padding: "15px",
                justifyContent: "center",
              }}
            />
          )}
        </Box>

        <VerticalDivider />

        <CardActionsOverview
          dealId={property.propertyId}
          property={property}
          propertyStatus={propertyStatus}
          setPropertyStatus={setPropertyStatus}
          onActionOverviewClick={() =>
            setOpenDrawer((prevState) => {
              getContractProgress();
              return {
                actionDrawer: !prevState.actionDrawer,
                interestDrawer: false,
              };
            })
          }
        />
      </Card>

      <Fade
        mountOnEnter
        unmountOnExit
        in={openDrawer.interestDrawer}
        onExit={() => scrollCardOutOfView(prevScrollableAreaPosition.current)}
      >
        {/* The component is wrapped in div to fix a MUI bug #27154 */}
        <div>
          <DealInterestDrawer
            property={property}
            dealId={property.propertyId}
            boxRef={boxRef}
            prevScrollableAreaPosition={prevScrollableAreaPosition}
            contractProgress={contractProgress}
            onHandleLvL2ClickItemClick={handleLvl2ItemClick}
          />
        </div>
      </Fade>
      <Fade
        mountOnEnter
        unmountOnExit
        in={openDrawer.actionDrawer}
        onExit={() => scrollCardOutOfView(prevScrollableAreaPosition.current)}
      >
        {/* The component is wrapped in div to fix a MUI bug #27154 */}
        <div>
          <DealActionDrawer
            dealId={property.propertyId}
            contractProgressId={property.contractProgressId}
            boxRef={boxRef}
            prevScrollableAreaPosition={prevScrollableAreaPosition}
            contractProgress={contractProgress}
            progressTracker={progressTracker}
            openProgressDrawer={openProgressDrawer}
            setOpenProgressDrawer={setOpenProgressDrawer}
            property={property}
            onHandleLvL2ClickItemClick={handleLvl2ItemClick}
            clickedLvl2ItemId={clickedItemId}
            setPropertyStatus={setPropertyStatus}
          />
        </div>
      </Fade>
    </Box>
  );
};

export default DealCard;
